* {
  font-family: "Montserrat", sans-serif;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: brushFont;
  src: url(fonts/brushFont.TTF);
}
.main-btn {
  color: #fff;
  background: #fcae76;
  transition: all 0.3s;
  position: relative;
  padding: 15px 20px;
  border-radius: 30px;
  text-decoration: none;
  margin: 0 4px;
  transition: 0.25s ease-in all;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.main-btn:hover {
  box-shadow: 0 6px 30px 10px rgba(25, 25, 25, 0.107);
  color: #fff;
}
.outline-btn {
  color: #fff;
  background: transparent;
  transition: all 0.3s;
  position: relative;
  padding: 10px 20px;
  border-radius: 30px;
  text-decoration: none;
  margin: 0 4px;
  border: 1px solid #fff;
  transition: 0.25s ease-in all;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.outline-btn:hover {
  box-shadow: 0 6px 30px 10px rgba(25, 25, 25, 0.107);
  color: #fff;
}
.bg-cover {
  background-image: url("../public/gian-cover-min.jpg");
  height: 98vh;
  opacity: 0.9;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.circles-section {
  display: flex;
  justify-content: center;
  padding: 0px 0px 30px 0px;
}

.circles-container {
  display: flex;
  flex-direction: column;
}

.instagram {
  background: url("../public/insta.jpg") no-repeat center center;
  height: 350px;
  width: 100%;
}

.circle-2 {
  /* background: url("../public/Circulo1.jpeg") no-repeat top; */
  background-size: cover;
  width: 290px;
  height: 290px;
  border-radius: 50%;
}
.circle-1 {
  /* background: url("../public/Circulo2.jpeg") no-repeat top; */
  background-size: cover;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.save-date-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px 25px 30px 25px;
}
.second-text-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0px 25px 20px 25px;
}
.title {
  font-size: 55px;
  font-weight: 500;
  color: #2d2d2d;
  font-family: brushFont;
  position: relative;
}
.title::after {
  content: "";
  position: absolute;
  left: 51%;
  bottom: 18px;
  width: 150px;
  height: 4px;
  transform: skew(-12deg) translateX(-50%);
  background: #fcae76;
  z-index: -5;
}
.dresscode::after {
  z-index: 100;
}
.mai::after {
  width: 50px;
  z-index: 50;
}
.hashtag::after {
  width: 180px;
}
.musica::after {
  width: 70px;
}
.resort-subtitle {
  font-size: 19px;
  font-weight: 600;
  color: #5f5f5f;
}
.save-date-text,
.second-text {
  font-size: 18px;
  font-weight: 400;
  color: rgba(85, 85, 85, 0.826);
  line-height: 35px;
}

.see-more {
  position: absolute;
  left: 40.5%;
  top: 87.9%;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-weight: 600;
}
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 21px;
  height: 21px;
  top: 93%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
  left: 53%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

.intro-circle {
  position: absolute;
  top: 25px;
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: center;
}
.intro-circle .cover-text-1 {
  color: white;
  font-size: 5rem;
  text-align: center;
  font-family: brushFont;
  text-align: center;
  text-shadow: 1px 1px 2px rgb(107, 103, 104);
}
.intro-circle .cover-text-2 {
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  top: -35px;
  text-shadow: 1px 1px 2px rgb(107, 103, 104);
}

.circle-name {
  font-size: 20px;
  line-height: 0;
}

.circle-and {
  font-size: 24px;
  margin: 14px 0;
}

.circle-and:after,
.circle-and:before {
  content: "";
  width: 60px;
  height: 1px;
  vertical-align: middle;
  display: inline-block;
  background: #000;
  margin: 0 8px;
}

.circle-date {
  font-size: 16px;
  margin-top: 15px;
}

/* REVEAL ANIMATIONS */

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 0.7s ease-in;
}
.active.fade-left {
  animation: fade-left 0.7s ease-in;
}
.active.fade-right {
  animation: fade-right 0.7s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Counter */
.countdown-container {
  text-align: center;
  margin: 25px auto 38px;
  width: 400px;
  border: 1.5px solid #fcae76;
  opacity: 84%;
  padding: 20px 0 40px;
  position: relative;
  white-space: nowrap;
  border-radius: 4px;
}

.countdown-container:before {
  content: "Faltan";
  position: absolute;
  top: -20px;
  color: #5f5f5f;
  background: #fff;
  width: 160px;
  display: block;
  font-size: 24px;
  left: 50%;
  font-weight: 500;
  margin-left: -80px;
}

.countdown-container::after {
  background-image: url("../public/fox2.png");
  content: "";
  position: absolute;
  display: inline-block;
  top: -92px;
  background-size: 120px 120px;
  width: 120px;
  height: 120px;
  color: #5f5f5f;
  left: 100%;
  margin-left: -110px;
  transform: scaleX(-1);
}
.principito-pseudo {
  width: 100%;
}
.principito-pseudo::before {
  background-image: url("../public/principito.png");
  content: "";
  position: absolute;
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  color: #5f5f5f;
}

.countdown-container > div {
  text-align: center;
  font-size: 42px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 16px;
}

.countdown-container > div:before {
  content: "Días";
  position: absolute;
  font-size: 20px;
  bottom: -20px;
  text-align: center;
  width: 100%;
  font-weight: 400;
  color: rgba(85, 85, 85, 0.826);
}

.countdown-container > div:nth-child(2):before {
  content: "Horas";
}

.countdown-container > div:nth-child(3):before {
  content: "Min";
}

.countdown-container > div:nth-child(4):before {
  content: "Seg";
}

.countdown-container > div:nth-child(4):after {
  display: none;
}

/* Party */
.party-container {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px 40px 20px 40px;
}
.party-container .moon {
  position: absolute;
  left: 0;
  top: 10;
  z-index: -10;
}
.party-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}
.spotify-container {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px 40px 20px 40px;
}
.event-time {
  border: 1px solid rgb(179, 148, 127);
  color: rgb(179, 148, 127);
  display: inline-block;
  border-radius: 30px;
  width: 127px;
  height: 42px;
  line-height: 21px;
  font-size: 20px;
  padding: 7px;
  font-weight: 600;
  vertical-align: middle;
}
.party-btn {
  color: #fff;
  background: rgb(179, 148, 127);
  transition: all 0.3s;
  position: relative;
  padding: 8px 20px;
  border-radius: 30px;
  text-decoration: none;
  transition: 0.25s ease-in all;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.party-btn:hover {
  box-shadow: 0 6px 30px 10px rgba(25, 25, 25, 0.107);
  color: #fff;
}

.img-gallery {
  width: 100vw;
  height: 80vh;
  object-fit: cover;
}

.prev-control {
  width: 2rem;
  margin-right: 10px;
  opacity: 0.6;
}
.prev-control:hover {
  opacity: 1;
}
.next-control {
  width: 2rem;
  margin-right: -10px;
  opacity: 0.6;
}
.next-control:hover {
  opacity: 1;
}
.video-container {
  width: 100%;
  height: 300px;
  position: relative;
}
.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.reserve-text-container {
  margin: 0px 5px 5px 5px;
  text-align: center;
}

.bg-dots {
  background-color: #ffffff;
  opacity: 1;
  background-image: radial-gradient(#53d4b2 1.05px, transparent 1.05px),
    radial-gradient(#eed9b6 1.05px, #ffffff 1.05px);
  background-size: 42px 42px;
  background-position: 0 0, 21px 21px;
  padding: 0 0 0 0;
}

@media (max-width: 430px) {
  .countdown-container > div {
    font-size: 38px;
  }
  .countdown-container {
    width: 90%;
  }
  .asistencia {
    font-size: 14.5px;
  }
}

@media (max-width: 43rem) {
  .bg-cover {
    background-position: center left 35%;
  }
  .circle-2 {
    width: 230px;
    height: 230px;
  }
  .circle-1 {
    width: 230px;
    height: 230px;
  }
  .countdown-container {
    width: 92%;
  }
  .countdown-container > div {
    font-size: 38px;
  }
  .countdown-container > div:before {
    font-size: 18px;
  }
  .countdown-container > div:nth-child(3):before {
    content: "Mins.";
  }
  .countdown-container > div:nth-child(4):before {
    content: "Segs.";
  }
  .video-container {
    margin-top: -40px;
  }
  .reserve-text-container {
    margin-top: -25px;
  }
}

/* Loader */
.heart-container {
  height: 90vh;
  width: 100vw;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  animation: opacityOn 5s normal forwards;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
.beatingHeart {
  width: 50px;
  height: 50px;
}

.beatingHeart .heart {
  position: absolute;
  width: 50px;
  height: 45px;
  opacity: 0.6;
  animation: beat 3s infinite ease-in-out;
  -webkit-animation: beat 3s infinite ease-in-out;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  left: 25px;
  top: 0;
  width: 25px;
  height: 40px;
  background: #ff69b4;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.fushiaHeart:before,
.fushiaHeart:after {
  background: #ff1d8e;
}
.pinkHeart:before,
.pinkHeart:after {
  background: #ff0080;
}

.heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.beatingHeart .heart2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.beatingHeart .heart3 {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.beatingHeart .heart4 {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.beatingHeart .heart5 {
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
}

.show-top {
  object-fit: cover;
  object-position: 25% 25%;
}

@keyframes opacityOn {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes beat {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
